import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { faUsersMedical } from '@fortawesome/pro-regular-svg-icons';
import { faTimesCircle } from '@fortawesome/pro-solid-svg-icons';
import { useMemo, useState } from 'react';
import { IconBox, ModalHeader, ModalShell, Select, SelectableTag } from '~/common/components';
import { toggleSet } from '~/common/utils';
import { useInitData, useTeamData, useTeamMutation } from '../hooks';
export const EditMembers = ({ onClose }) => {
    const team = useTeamData();
    const init = useInitData();
    const [designerIds, setDesignerIds] = useState(new Set(team.designers.map(({ id }) => id)));
    const { isLoading, mutateAsync } = useTeamMutation();
    const uploadMembers = () => {
        mutateAsync({ designer_ids: [...designerIds] }).then(onClose);
    };
    const toggleDesigner = toggleSet(setDesignerIds);
    const currentTeamDesigners = useMemo(() => team.designers.map(({ id: value, name }) => ({ name, value })), [team.designers]);
    const allDesigners = useMemo(() => [...init.teamless_designers, ...currentTeamDesigners], [currentTeamDesigners, init.teamless_designers]);
    const selectableDesigners = useMemo(() => allDesigners.filter(({ value }) => !designerIds.has(value)), [allDesigners, designerIds]);
    return (_jsxs(_Fragment, { children: [_jsx(ModalHeader, { onClose: onClose, title: designerIds.size ? 'Edit members' : 'Add a member' }), _jsxs(ModalShell, { className: "p-3 spacing-x-4 w-[712px]", loading: isLoading, onClose: onClose, onSubmit: uploadMembers, children: [_jsx(Select, { icon: faUsersMedical, title: "Add a member", placeholder: "Choose a team member", options: selectableDesigners, onChange: toggleDesigner, value: null, size: "small" }), _jsx("div", { "aria-label": "Assigned team members", className: "flex flex-wrap gap-1 mt-3", children: [...designerIds].map((id) => {
                            var _a;
                            return (_jsx(SelectableTag, { onClick: () => toggleDesigner(id), label: _jsxs("span", { className: "flex items-center gap-1 font-brand-t5 text-greyscale-500", children: [(_a = allDesigners.find((designer) => designer.value === id)) === null || _a === void 0 ? void 0 : _a.name, _jsx(IconBox, { size: "s", icon: faTimesCircle })] }) }, id));
                        }) })] })] }));
};
