import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link, StatsBlock, StatsCard } from '~/common/components';
import { formatMoney } from '~/common/utils';
import { ORDERS_URL } from '~/orders';
import { useOrganizationData, useStatsData } from '~/organizations/hooks';
export const Stats = () => {
    const stats = useStatsData();
    const organization = useOrganizationData();
    return (_jsxs("div", { className: "grid gap-2 grid-cols-4 row-start-1 row-end-2", children: [_jsx(StatsCard, { generalProps: { heading: 'Total customers', value: organization.customers_count } }), _jsx(StatsCard, { generalProps: { heading: 'Total members', value: organization.members_count } }), _jsxs(StatsBlock, { className: "col-span-2", children: [_jsx(StatsBlock.Item, { generalProps: {
                            heading: 'Orders',
                            value: organization.orders_count,
                            description: 'All time',
                        }, action: _jsx(Link, { size: "m-bold", to: `${ORDERS_URL}?filter[organisation]=[${organization.id}]`, children: "All orders" }), graphProps: { data: stats.orders, color: '#F86666' } }), _jsx(StatsBlock.Item, { generalProps: {
                            heading: 'Orders value',
                            value: formatMoney(stats.avg_monthly_orders_value),
                            description: 'for last 12 months',
                        }, graphProps: { data: stats.monthly_orders_value, color: '#7394E8' }, action: _jsx(Link, { newTab: true, external: true, size: "m-bold", href: "https://insights.24slides.dev/", children: "Overview" }) })] }), _jsxs(StatsBlock, { className: "col-span-2 col-start-3 row-span-2 row-start-1", children: [_jsx(StatsBlock.Item, { generalProps: {
                            heading: 'Total Revenue',
                            value: formatMoney(stats.total_subscription_revenue + stats.total_regular_revenue),
                            description: 'for last 12 months',
                        }, graphProps: { data: stats.revenue, color: '#46B688' }, action: _jsx(Link, { newTab: true, external: true, size: "m-bold", href: "https://insights.24slides.dev/", children: "Overview" }) }), _jsx(StatsBlock.Item, { generalProps: {
                            heading: 'Subscriptions revenue',
                            value: formatMoney(stats.total_subscription_revenue),
                            description: 'for last 12 months',
                        }, graphProps: { data: stats.monthly_orders_value, color: '#FFBA0A' }, action: _jsx(Link, { newTab: true, external: true, size: "m-bold", href: "https://insights.24slides.dev/", children: "Overview" }) }), _jsx(StatsBlock.Item, { generalProps: {
                            heading: 'Regular revenue',
                            value: formatMoney(stats.total_regular_revenue),
                            description: 'for last 12 months',
                        }, graphProps: { data: stats.monthly_orders_value, color: '#8A6DD9' }, action: _jsx(Link, { newTab: true, external: true, size: "m-bold", href: "https://insights.24slides.dev/", children: "Overview" }) })] })] }));
};
