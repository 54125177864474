import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { isFuture } from 'date-fns';
import { Badge } from '~/common/components';
import { cx, formatDateShort } from '~/common/utils';
import { subscriptionStatusMap, } from '~/customers/domain';
import styles from './SubscriptionInfo.module.scss';
export const SubscriptionInfo = ({ products, status, dates }) => {
    const getCurrentStatus = () => {
        if (status.value === subscriptionStatusMap.scheduled) {
            return status.name + (dates.starts_at ? ` on ${formatDateShort(dates.starts_at)}` : '');
        }
        if (status.value === subscriptionStatusMap.on_trial) {
            return dates.trial_ends_at
                ? `Trial ends on ${formatDateShort(dates.trial_ends_at)}`
                : status.name;
        }
        if (status.value === subscriptionStatusMap.cancelled) {
            return dates.ends_at && isFuture(dates.ends_at)
                ? `Cancellation on ${formatDateShort(dates.ends_at)}`
                : status.name;
        }
        return status.name;
    };
    return (_jsxs("div", { children: [_jsxs("h4", { className: "font-brand-b1 text-greyscale-400", children: ["Current plan", _jsx("span", { className: cx('ml-1', styles.status, styles[status.color]), children: getCurrentStatus() })] }), _jsx("div", { className: "flex mt-1 gap-x-1", children: products.map(({ plan: { name }, id }) => (_jsx(Badge, { color: "grey", className: "font-brand-t2m px-2 py-[4px]", children: name }, id))) })] }));
};
