import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faInfoCircle, faPlus } from '@fortawesome/pro-regular-svg-icons';
import { Button, IconBox, SearchField, Tooltip, useModalContext } from '~/common/components';
import { Filter, getFilterProps, stringify, useParsedQuery } from '~/common/kits/table';
import { useCustomerData, useInitData } from '~/customers/hooks';
import { useCurrentUserData } from '~/root';
import { InviteTeamMember } from './InviteTeamMember';
const getTransformedFilters = (roles, statuses) => ({
    role: roles,
    status: statuses,
    search: [],
});
export const Header = () => {
    const { queryParams, onFilterChange } = useParsedQuery();
    const { permissions } = useCurrentUserData();
    const { members } = useCustomerData();
    const { invite_roles, statuses } = useInitData();
    const { modalOpener } = useModalContext();
    const filterProps = getFilterProps(getTransformedFilters(invite_roles, statuses), queryParams.filter, onFilterChange);
    return (_jsxs("div", { className: "flex justify-between items-center p-2 bg-greyscale-0 border-b border-solid border-greyscale-200", children: [_jsxs("div", { className: "flex", children: [_jsx(SearchField, { className: "w-[272px]", placeholder: "Search", value: stringify(queryParams.filter.search), onChange: (value) => onFilterChange('search', value) }), _jsx(Filter, { ...filterProps('role'), placeholder: "Member role", className: "ml-2 w-20" }), _jsx(Filter, { ...filterProps('status'), placeholder: "Status", className: "ml-2 w-20" })] }), _jsxs("div", { className: "ml-auto flex items-center gap-[4px] text-greyscale-600", children: [_jsx("span", { className: "font-brand-t1m", children: members.total }), _jsx("span", { className: "font-brand-b1", children: "Total members" }), _jsx(Tooltip, { clickable: true, placement: "bottom", content: _jsxs("div", { className: "font-brand-c1r ", children: [_jsxs("div", { className: "flex items-center gap-1", children: [_jsx("span", { className: "font-brand-t2m text-primary-300", children: members.active }), " Active members"] }), _jsxs("div", { className: "flex items-center gap-1", children: [_jsx("span", { className: "font-brand-t2m text-warning-400", children: members.pending }), " Pending invites"] }), _jsxs("div", { className: "flex items-center gap-1", children: [_jsx("span", { className: "font-brand-t2m text-danger-400", children: members.deleted }), " Deleted members"] })] }), children: _jsx(IconBox, { size: "s", className: "cursor-help", icon: faInfoCircle }) })] }), permissions.customersMembersInvite && (_jsx(Button, { onClick: modalOpener(InviteTeamMember), color: "primary", icon: faPlus, className: "ml-2", children: "Invite" }))] }));
};
