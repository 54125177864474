import { z } from 'zod';
import { FeatureValues } from '~/common/kits/features';
import { UserAvatarInfo } from '~/common/kits/usercard';
import { TreatmentsTable } from '~/common/other';
import { DateFromIsoString, Email, Link, twoDecimalsPrecision } from '~/common/utils';
import { CardBrand, Currency, CustomPortalStyle, CustomStyle, NumericIdNamePair, PhoneNumber, UploadedFile, } from '~/root';
export const CustomerLink = z.object({
    label: z.string(),
    url: Link,
});
export const CustomerOrganization = z.object({
    id: z.number(),
    name: z.string(),
    amount: z.number(),
    portal_url: z.string().nullable(),
    logo: UploadedFile.nullable(),
});
export const BillingSettings = z.object({
    email: Email.nullable(),
    cc: z.array(Email).nullable(),
    invoice_enabled: z.boolean(),
    add_credits_immediately: z.boolean(),
    payment_term: z.number(),
    invoice_lines: z.string().nullable(),
    enable_auto_charge: z.boolean(),
});
export const BillingShared = z.object({
    address: z.string().nullable(),
    city: z.string().nullable(),
    company: z.string().nullable(),
    country_id: z.number().nullable(),
    currency: z.string().nullable(),
    phone_number: PhoneNumber.nullable(),
    po_number: z.string().nullable(),
    po_expires_at: DateFromIsoString.nullable(),
    region: z.string().nullable(),
    zip_code: z.string().nullable(),
});
export const BillingAddress = BillingShared.extend({
    vat: z.string().nullable(),
}).transform((billingAddress) => ({
    ...billingAddress,
    vat_number: billingAddress.vat,
}));
export const CardPaymentMethod = z.object({
    id: z.number(),
    default: z.boolean(),
    brand: CardBrand,
    expires: z.string().nullable(),
    last4: z.string(),
});
export const InvoicePaymentMethod = BillingShared.extend({
    id: z.number(),
    default: z.boolean(),
    attention: z.string().nullable(),
    invoice_lines: z.string().nullable(),
    vat_number: z.string().nullable(),
});
export const PaymentMethod = z.union([CardPaymentMethod, InvoicePaymentMethod]);
export const DedicatedTeam = z.object({
    design_team: NumericIdNamePair.nullable(),
});
export const SuccessTeam = z.object({
    success_manager: UserAvatarInfo.nullable(),
    business_partner: UserAvatarInfo.nullable(),
    account_manager: UserAvatarInfo.nullable(),
});
export const SubscriptionStatusColor = z.enum(['danger', 'success', 'warning', 'info']);
export const SubscriptionStatus = z
    .object({
    id: z.number(),
    name: z.string(),
    color: SubscriptionStatusColor,
})
    .transform(({ id, ...rest }) => ({ ...rest, value: id }));
export const subscriptionStatusMap = {
    active: 1,
    on_trial: 2,
    incomplete: 3,
    past_due: 5,
    unpaid: 6,
    cancelled: 7,
    scheduled: 8,
};
export const RenewalPeriod = z.number();
export const ProductType = z.enum(['retainer', 'techpack']);
const CustomProductPlan = NumericIdNamePair.and(z.object({
    amount: z.number(),
    is_internal: z.boolean(),
}));
export const RetainerProductPlan = CustomProductPlan.and(z
    .object({
    credits: z.number(),
    daily_points: z.number(),
    supports_credits: z.boolean(),
    supports_daily_points: z.boolean(),
    supports_design_team: z.boolean(),
    supports_project_manager: z.boolean(),
    supports_addons: z.boolean(),
    success_manager_id_required: z.boolean(),
    business_partner_id_required: z.boolean(),
    account_manager_id_required: z.boolean(),
    type: z.literal('retainer'),
})
    .transform(({ supports_addons, ...pp }) => ({ ...pp, enable_addons: supports_addons })));
export const TechPackProductPlan = CustomProductPlan.and(z.object({
    supports_custom_fields: z.boolean(),
    supports_custom_portal: z.boolean(),
    supports_sso: z.boolean(),
    type: z.literal('techpack'),
}));
export const ProductPlan = z.union([RetainerProductPlan, TechPackProductPlan]);
const CommonProduct = z.object({
    id: z.number(),
    amount: z.number(),
});
export const RetainerSubscriptionProduct = CommonProduct.extend({
    plan: RetainerProductPlan,
    daily_points: z.number().nullable(),
    credits: z.number().nullable(),
    keep_individual_prices: z.boolean(),
    enable_topup: z.boolean(),
    enable_addons: z.boolean(),
});
export const TechpackSubscriptionProduct = CommonProduct.extend({
    plan: TechPackProductPlan,
    enable_custom_fields: z.boolean().nullable(),
    enable_custom_portal: z.boolean().nullable(),
    enable_sso: z.boolean().nullable(),
});
export const SubscriptionProduct = z.union([
    RetainerSubscriptionProduct,
    TechpackSubscriptionProduct,
]);
const Subscription = z.object({
    amount: z.number(),
    cancellation_context: z.string().nullable(),
    cancellation_reason: z.string().nullable(),
    capacity_overview_link: z.string().nullable(),
    company_name: z.string().nullable(),
    created_at: DateFromIsoString,
    credit_balance: z.number(),
    currency: Currency,
    ends_at: DateFromIsoString.nullable(),
    id: z.number(),
    owner_id: z.number(),
    owner_name: z.string(),
    payment_method: PaymentMethod.nullable(),
    products: z.array(SubscriptionProduct),
    renewal_period: RenewalPeriod,
    renews_at: DateFromIsoString.nullable(),
    send_invoice: z.boolean(),
    starts_at: DateFromIsoString.nullable(),
    status: SubscriptionStatus,
    trial_ends_at: DateFromIsoString.nullable(),
    updated_at: DateFromIsoString.nullable(),
});
export const CustomerProjectManager = z.object({
    id: z.number(),
    priority: z.number(),
    name: z.string(),
    avatar_link: z.string().nullable(),
});
export const Customer = z
    .object({
    id: z.number(),
    customer_account_id: z.number().int().positive(),
    avatar: UploadedFile.nullable(),
    type: z.array(z.number()),
    name: z.string(),
    credits: z.number(),
    last_update: DateFromIsoString.nullable(),
    created_at: DateFromIsoString,
    phone_number: PhoneNumber.nullable(),
    email: Email,
    department: z.string().nullable(),
    calendly_link: Link.nullable(),
    sign_on_link: z.string().nullable(),
    capacity_overview_link: z.string().nullable(),
    links: z.array(CustomerLink).nullable(),
    company: z.string().nullable(),
    organisation: CustomerOrganization.nullable(),
    country_id: z.number().nullable(),
    payment_methods: z.array(PaymentMethod),
    billing_address: BillingAddress,
    billing_settings: BillingSettings,
    dedicated_team: DedicatedTeam,
    success_team: SuccessTeam,
    treatments: TreatmentsTable,
    is_suspended: z.boolean(),
    subscription: Subscription.nullable(),
    suspension_reason: z.string().nullable(),
    partnership_logo: UploadedFile.nullable(),
    enable_custom_portal: z.boolean(),
    features: FeatureValues,
    members: z.object({
        active: z.number(),
        deleted: z.number(),
        pending: z.number(),
    }),
    project_managers: z.array(CustomerProjectManager),
    styles: z.object({
        custom_portal_styles: z.array(CustomPortalStyle),
        custom_styles: z.array(CustomStyle),
        archived_styles: z.array(CustomStyle),
    }),
})
    .transform(({ payment_methods, members, ...customer }) => ({
    ...customer,
    payment_methods,
    project_managers_list: customer.project_managers.map((pm) => ({
        name: pm.name,
        value: pm.id,
    })),
    members: {
        ...members,
        total: members.active + members.pending + members.deleted,
    },
    invoicePaymentMethod: payment_methods.find((paymentMethod) => 'company' in paymentMethod),
}));
export const Stats = z
    .object({
    revenue: z.array(z.number()),
    orders: z.array(z.number()),
    feedback: z.array(z.number()),
})
    .transform((stats) => {
    const nonZeroFeedback = stats.feedback.filter(Boolean);
    return {
        ...stats,
        revenueTotal: twoDecimalsPrecision(stats.revenue.reduce((total, value) => total + value, 0)),
        ordersTotal: twoDecimalsPrecision(stats.orders.reduce((total, value) => total + value, 0)),
        feedbackAvg: (nonZeroFeedback.reduce((total, value) => total + value, 0) / nonZeroFeedback.length || 0).toFixed(1),
    };
});
export const LoginAsCustomerLink = z.object({ link: Link }).transform(({ link }) => link);
