import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useForm, validator } from 'formoid';
import { useMemo } from 'react';
import { InputField } from '~/common/components';
import { customValidator } from '~/common/utils';
import { otherReasonPrefixes } from '~/orders/domain';
import { ButtonGroup } from '../ButtonGroup';
import { AdjustExtraCapacityReasons } from './AdjustExtraCapacityReasons';
export const AdjustExtraCapacity = ({ onApply, onClose, reasons, value }) => {
    const reasonOptions = reasons.adjust_extra_capacity;
    const initialValues = useMemo(() => {
        return {
            value,
            reason: null,
            customReason: null,
        };
    }, [value]);
    const { fieldProps, handleSubmit, values } = useForm({
        initialValues,
        validationStrategy: 'onBlur',
        validators: ({ reason }) => {
            var _a;
            return ({
                value: customValidator.positiveInteger(),
                reason: customValidator.required('Please, choose a reason'),
                customReason: !!((_a = reasonOptions.find((r) => r.name === reason)) === null || _a === void 0 ? void 0 : _a.prefix)
                    ? validator.sequence(customValidator.required('Please fill out this field'), customValidator.nonEmptyString())
                    : null,
            });
        },
    });
    const prefix = otherReasonPrefixes.find((prefix) => {
        const currentReason = reasonOptions.find((r) => r.name === values.reason);
        return (currentReason === null || currentReason === void 0 ? void 0 : currentReason.prefix) === prefix;
    });
    const submit = () => handleSubmit((values) => {
        const reason = prefix ? prefix + values.customReason : values.reason;
        return reason
            ? Promise.resolve(onApply({ value: values.value, reason })).then(onClose)
            : Promise.reject(null);
    });
    const handleToggleReason = (reason) => {
        fieldProps('reason').onChange(values.reason === reason ? null : reason);
        fieldProps('reason').onBlur();
    };
    return (_jsxs("div", { className: "space-y-2", children: [_jsxs("div", { className: "space-y-1", children: [_jsx("p", { className: "font-normal text-greyscale-500 text-sm", children: "Adjust extra capacity" }), _jsx(InputField, { ...fieldProps('value'), id: "amount", type: "number" }), _jsx("p", { className: "font-normal text-greyscale-500 text-sm", children: "Choose a reason" }), _jsx(AdjustExtraCapacityReasons, { errors: fieldProps('reason').errors, onToggleReason: handleToggleReason, reasons: reasonOptions.map(({ name }) => name), selectedReason: values.reason }), !!prefix && (_jsx(InputField, { ...fieldProps('customReason'), id: "customReason", placeholder: "Give more context (for Louise)", type: "text" }))] }), _jsx(ButtonGroup, { onApply: submit, onCancel: onClose })] }));
};
