import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faBriefcase, faFileImage, faTimes, faUser } from '@fortawesome/pro-regular-svg-icons';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { useMemo } from 'react';
import { AvatarPane, Button, CountryInfoBar, GeneralInfoHeader, IconBox, IconButton, ImageLoader, InfoBar, Link, Tooltip, useModalContext, } from '~/common/components';
import { UserCard } from '~/common/kits/usercard';
import { cx, getColorsFromNumber } from '~/common/utils';
import { CreditsBanner } from '~/customers/Customer';
import { CUSTOMERS_URL } from '~/customers/hooks';
import { useCurrentUserData } from '~/root';
import { useSubscriptionData } from '~/subscriptions/hooks';
import { Topup } from '../Topup';
import { EditSubscriptionInfo } from './EditSubscriptionInfo';
export const SubscriptionInfo = () => {
    const subscription = useSubscriptionData();
    const { permissions } = useCurrentUserData();
    const { modalOpener } = useModalContext();
    const colorSet = getColorsFromNumber(subscription.owner_id);
    const canTopup = useMemo(() => Boolean(subscription.products.filter((product) => 'credits' in product).length), [subscription.products]);
    return (_jsxs("div", { className: "bg-greyscale-0 pb-2 rounded overflow-hidden", children: [_jsx(AvatarPane, { colorSet: colorSet, avatarChildren: _jsx(IconBox, { size: "l", icon: faBriefcase, className: "text-inherit" }), children: permissions.subscriptionsUpdate && (_jsx(Button, { color: "grey", onClick: modalOpener(EditSubscriptionInfo), children: "Edit" })) }), _jsxs("div", { className: "px-4", children: [_jsx(GeneralInfoHeader, { name: subscription.company_name || subscription.owner_name, created_at: subscription.created_at, last_update: subscription.updated_at }), _jsx(CreditsBanner, { className: "mt-2", amount: subscription.credit_balance, children: _jsx(Button, { color: "text-secondary", icon: faPlus, onClick: modalOpener(Topup), disabled: !canTopup, "data-tt": canTopup
                                ? null
                                : 'Top up only available for retainer products, and there are none of them in this subscription', children: "Top up" }) }), _jsxs("ul", { className: "mt-1", children: [_jsx(InfoBar, { icon: faUser, text: _jsx(UserCard, { id: subscription.owner_id, placement: "right", children: _jsx(Link, { size: "m", to: `${CUSTOMERS_URL}/${subscription.owner_id}`, children: subscription.owner_name }) }), hint: "Subscription owner" }), subscription.country && (_jsx(CountryInfoBar, { country_code: subscription.country.country_code, name: subscription.country.name })), _jsx(InfoBar, { className: cx({ 'cursor-pointer': subscription.partnership_logo }), onClick: subscription.partnership_logo ? modalOpener(ViewPartnershipLogo) : undefined, icon: faFileImage, text: subscription.partnership_logo && (_jsx(Tooltip, { className: "w-30 p-0", placement: "right", noArrow: true, content: subscription.partnership_logo && (_jsx(ImageLoader, { className: "rounded w-full", src: subscription.partnership_logo.link })), children: _jsx("span", { children: "Partnership logo" }) })), hint: "Partnership logo" })] })] })] }));
};
const ViewPartnershipLogo = ({ onClose }) => {
    var _a;
    const subscription = useSubscriptionData();
    return (_jsxs("div", { className: "relative w-[588px]", children: [_jsx(IconButton, { className: "absolute right-2 top-2 w-4 h-4 text-[32px] text-greyscale-500 focus-visible:ring", icon: faTimes, onClick: onClose }), _jsx(ImageLoader, { className: "object-cover w-full", src: (_a = subscription.partnership_logo) === null || _a === void 0 ? void 0 : _a.link })] }));
};
