import { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useStateSliceSetter } from '~/common/hooks';
import { addSlug, lastItem, noop } from '~/common/utils';
import { ORDERS_URL } from '~/orders';
import { useCurrentUserData } from '~/root';
import { useOrderData } from '../useOrder';
import { useUploadOrderSlides } from '../useUploadOrderSlides';
import { isSlide, usePreviewsState } from './usePreviewsState';
const UNEDITABLE_STATUSES = ['FeedbackReceived', 'Completed', 'Cancelled'];
const getAnnotationInfo = (previews) => {
    const info = { hasAnnotations: false, hasUnresolvedAnnotations: false };
    for (const preview of previews.filter(isSlide)) {
        if (preview.annotations.length) {
            info.hasAnnotations = true;
        }
        if (preview.annotations.some((annotation) => !annotation.resolved)) {
            info.hasUnresolvedAnnotations = true;
        }
        if (info.hasAnnotations && info.hasUnresolvedAnnotations) {
            break;
        }
    }
    return info;
};
const isWrongFilename = (id, name) => {
    var _a;
    return (((_a = name === null || name === void 0 ? void 0 : name.match(/^\d+/)) === null || _a === void 0 ? void 0 : _a[0]) || '') !== String(id);
};
export function useRevision() {
    var _a, _b, _c, _d, _e, _f;
    const { id, revisions, status, customer, slides } = useOrderData();
    // TODO extract this, but keep setSelectedRevisionId usable outside?
    const selectOptions = useMemo(() => {
        return revisions.map(({ id, name }) => ({ value: id, name })) || [];
    }, [revisions]);
    const initialSelectedValue = (_b = (_a = lastItem(selectOptions)) === null || _a === void 0 ? void 0 : _a.value) !== null && _b !== void 0 ? _b : null;
    const [selectedRevisionId, setSelectedRevisionId] = useState(initialSelectedValue);
    useEffect(() => setSelectedRevisionId(initialSelectedValue), [initialSelectedValue]);
    //
    const history = useHistory();
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);
    const urlAnnotationId = searchParams.get('annotation-id');
    const { permissions } = useCurrentUserData();
    const selectedRevision = revisions.find(({ id }) => id === selectedRevisionId);
    const [context, setContext] = useState({
        file: selectedRevision === null || selectedRevision === void 0 ? void 0 : selectedRevision.file,
        fileToUpload: undefined,
        link: selectedRevision === null || selectedRevision === void 0 ? void 0 : selectedRevision.url,
        addingNewRevision: !revisions.length,
        editing: !revisions.length,
        abortController: new AbortController(),
        resetKey: 0,
        annotationListOpened: false,
    });
    const setEditing = useStateSliceSetter(setContext, 'editing');
    const setLink = useStateSliceSetter(setContext, 'link');
    const setResetKey = useStateSliceSetter(setContext, 'resetKey');
    const setAnnotationListOpened = useStateSliceSetter(setContext, 'annotationListOpened');
    const previews = usePreviewsState({
        slides: selectedRevision === null || selectedRevision === void 0 ? void 0 : selectedRevision.slides,
        resetKey: context.resetKey,
        addingNewRevision: context.addingNewRevision,
        editing: context.editing,
        urlAnnotationId: urlAnnotationId ? parseInt(urlAnnotationId) : undefined,
    });
    const previewsUpload = useUploadOrderSlides();
    const cancelUploads = () => {
        previewsUpload.onCancel();
        context.abortController.abort();
    };
    const goToOrder = () => history.push(`${ORDERS_URL}/${id}-${slides}${addSlug(customer.name)}`);
    const actions = {
        enableEditing: () => setEditing(true),
        disableEditing: () => {
            setEditing(false);
            if (!context.addingNewRevision) {
                setResetKey((prev) => prev + 1);
            }
        },
        addNewRevision: () => {
            setContext((prev) => {
                prev.abortController.abort();
                return {
                    ...prev,
                    addingNewRevision: true,
                    editing: true,
                    file: undefined,
                    fileToUpload: undefined,
                    link: undefined,
                    abortController: new AbortController(),
                };
            });
            previews.action.removeAllPreviews();
        },
        cancelCreatingNewRevision: () => {
            previewsUpload.onCancel();
            setContext((prev) => {
                prev.abortController.abort();
                return {
                    ...prev,
                    addingNewRevision: false,
                    editing: false,
                };
            });
        },
        toggleAnnotationList: () => setAnnotationListOpened((v) => !v),
    };
    const fileActions = {
        onFileUpload: (file) => {
            setContext((prev) => ({ ...prev, file, fileToUpload: undefined }));
        },
        // TODO add error handling
        onUploadingError: noop,
        onFileRemove: () => {
            setContext((prev) => {
                prev.abortController.abort();
                return {
                    ...prev,
                    file: undefined,
                    fileToUpload: undefined,
                    abortController: new AbortController(),
                };
            });
        },
        onFileSelection: useStateSliceSetter(setContext, 'fileToUpload'),
    };
    const linkActions = {
        addLinkToRevision: setLink,
        removeLinkFromRevision: () => setLink(undefined),
    };
    const { hasAnnotations, hasUnresolvedAnnotations } = useMemo(() => getAnnotationInfo(previews.state.previews), [previews.state.previews]);
    const lastRevisionSelected = !!(revisions.length && ((_c = lastItem(revisions)) === null || _c === void 0 ? void 0 : _c.id) === selectedRevisionId);
    const isRevisionMainFileAdded = Boolean(context.file || context.fileToUpload || context.link);
    const canEnableEditMode = permissions.ordersUpdate &&
        lastRevisionSelected &&
        !hasAnnotations &&
        !UNEDITABLE_STATUSES.includes(status);
    const isWrongFile = !!(context.file || context.fileToUpload) &&
        isWrongFilename(id, ((_d = context.file) === null || _d === void 0 ? void 0 : _d.name) || ((_e = context.fileToUpload) === null || _e === void 0 ? void 0 : _e.name));
    const canSendToCustomer = Boolean((context.file || context.link) && (previews === null || previews === void 0 ? void 0 : previews.state.previews.length));
    // TODO these are ugly
    const fileDirty = useMemo(() => {
        var _a, _b;
        if (context.fileToUpload) {
            return true;
        }
        if (context.addingNewRevision) {
            return Boolean(context.file || context.link);
        }
        else {
            return Boolean(((_a = context.file) === null || _a === void 0 ? void 0 : _a.id) !== ((_b = selectedRevision === null || selectedRevision === void 0 ? void 0 : selectedRevision.file) === null || _b === void 0 ? void 0 : _b.id) || context.link !== (selectedRevision === null || selectedRevision === void 0 ? void 0 : selectedRevision.url));
        }
    }, [
        context.addingNewRevision,
        context.file,
        context.fileToUpload,
        context.link,
        (_f = selectedRevision === null || selectedRevision === void 0 ? void 0 : selectedRevision.file) === null || _f === void 0 ? void 0 : _f.id,
        selectedRevision === null || selectedRevision === void 0 ? void 0 : selectedRevision.url,
    ]);
    const previewsDirty = useMemo(() => {
        if (previewsUpload.isLoading) {
            return true;
        }
        if (context.addingNewRevision) {
            return previews.state.previews.length > 0;
        }
        else {
            if (selectedRevision) {
                const prev = selectedRevision.slides.map(({ id }) => id).join(',');
                const next = previews.state.previews.map(({ id }) => id).join(',');
                return prev !== next;
            }
            return false;
        }
    }, [
        context.addingNewRevision,
        previews.state.previews,
        previewsUpload.isLoading,
        selectedRevision,
    ]);
    useLayoutEffect(() => {
        if (context.editing) {
            return;
        }
        setContext((prev) => ({
            ...prev,
            file: selectedRevision === null || selectedRevision === void 0 ? void 0 : selectedRevision.file,
            link: selectedRevision === null || selectedRevision === void 0 ? void 0 : selectedRevision.url,
            abortController: new AbortController(),
        }));
    }, [context.editing, selectedRevision === null || selectedRevision === void 0 ? void 0 : selectedRevision.file, selectedRevision === null || selectedRevision === void 0 ? void 0 : selectedRevision.url]);
    // clear annotation id from url
    useLayoutEffect(() => {
        history.replace({ search: '' });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return {
        ...context,
        ...actions,
        fileActions,
        linkActions,
        fileDirty,
        previewsDirty,
        previewsState: previews.state,
        previewsActions: previews.action,
        previewsUpload,
        hasUnsavedChanges: fileDirty || previewsDirty,
        canSendToCustomer,
        hasAnnotations,
        hasUnresolvedAnnotations,
        isRevisionMainFileAdded,
        isWrongFile,
        goToOrder,
        cancelUploads,
        canEnableEditMode,
        lastRevisionSelected,
        revisionSelect: {
            value: selectedRevisionId,
            options: selectOptions,
            onChange: setSelectedRevisionId,
        },
    };
}
