import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useForm, validator } from 'formoid';
import { useEffect } from 'react';
import { BlockSelect, InputField, ModalHeader, ModalStepShell, RangeSelect, SelectField, Show, } from '~/common/components';
import { useStep } from '~/common/hooks';
import { propagateBackendValidationErrors, getFirstStepWithErrors, minLengthMessage, requiredMessage, } from '~/common/utils';
import { BadgeSelect } from '../BadgeSelect';
import { useInitData, useOfficeCreation } from '../hooks';
import { addressValidator, transformFormValues, typeToIcon, workingHoursValidator, } from '../utils';
const MAX_STEP = 2;
const firstStepWithErrors = (errors) => {
    return getFirstStepWithErrors(errors, {
        1: ['name', 'country_id', 'region', 'city', 'address'],
    });
};
export const CreateOffice = ({ onClose }) => {
    const init = useInitData();
    const { mutateAsync } = useOfficeCreation();
    const { step, toPrevStep, toNextStep, toStep } = useStep(MAX_STEP);
    const initialValues = {
        type: 'design',
        name: '',
        country_id: null,
        region: '',
        city: '',
        address: '',
        workingHours: [4, 16],
        shift_ids: [],
    };
    const { fieldProps, handleSubmit, isSubmitting, errors, setErrors } = useForm({
        initialValues,
        validationStrategy: 'onBlur',
        validators: ({ region }) => ({
            type: null,
            name: step === 1 ? validator.minLength(2, minLengthMessage('Name', 2)) : null,
            country_id: step === 1 ? validator.defined(requiredMessage('Country')) : null,
            region: step === 1 && region !== '' ? validator.minLength(2, minLengthMessage('Region', 2)) : null,
            city: step === 1 ? validator.minLength(2, minLengthMessage('City', 2)) : null,
            address: step === 1 ? addressValidator() : null,
            workingHours: workingHoursValidator(),
            shift_ids: null,
        }),
    });
    const submit = () => handleSubmit((values) => {
        if (step < MAX_STEP) {
            toNextStep();
            return Promise.resolve();
        }
        return mutateAsync(transformFormValues(values))
            .then(onClose)
            .catch((error) => {
            propagateBackendValidationErrors(error, setErrors);
        });
    });
    useEffect(() => {
        const stepWithErrors = firstStepWithErrors(errors);
        if (stepWithErrors) {
            toStep(stepWithErrors);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errors]);
    return (_jsxs(_Fragment, { children: [_jsx(ModalHeader, { onClose: onClose, title: "Create office" }), _jsxs(ModalStepShell, { className: "flex flex-col gap-3 min-h-[424px] w-[600px] p-3", submitText: "Create office", step: step, toPrevStep: toPrevStep, maxStep: MAX_STEP, onClose: onClose, onSubmit: submit, loading: isSubmitting, children: [_jsx(Show, { if: step === 0, children: _jsx(BlockSelect, { ...fieldProps('type'), options: init.office_type, nameToIcon: typeToIcon }) }), _jsxs(Show, { if: step === 1, children: [_jsx(InputField, { ...fieldProps('name'), title: "Name", placeholder: "Enter an office name", type: "text" }), _jsx(SelectField, { ...fieldProps('country_id'), title: "Country", placeholder: "Select a country", options: init.countries, type: "text" }), _jsxs("div", { className: "grid grid-cols-2 gap-5", children: [_jsx(InputField, { ...fieldProps('region'), title: "Region / state (optional)", placeholder: "Enter a region or state", type: "text" }), _jsx(InputField, { ...fieldProps('city'), title: "City", placeholder: "Enter a city", type: "text" })] }), _jsx(InputField, { ...fieldProps('address'), title: "Address", placeholder: "Enter street, building, zip code", type: "text" })] }), _jsxs(Show, { if: step === 2, children: [_jsx(RangeSelect, { ...fieldProps('workingHours'), title: "Working hours", render: (value) => `${value}:00`, min: 0, max: 23 }), _jsx(BadgeSelect, { ...fieldProps('shift_ids'), title: "Shifts (optional)", badges: init.shifts })] })] })] }));
};
