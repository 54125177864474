import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faEllipsisVertical } from '@fortawesome/pro-regular-svg-icons';
import { Badge, Button, ChunkRenderer, Link, Menu, Show } from '~/common/components';
import { getFilterProps, useFrontendTable, useParsedQuery, } from '~/common/kits/table';
import { cx, nonNullable } from '~/common/utils';
import { ORDERS_URL } from '~/orders';
import { useInitData, useOrdersForBoardData } from '../../hooks';
import { BoardItem } from './BoardItem';
const isClosedOrCanceled = (status) => [6, 7].includes(status);
const tableConfig = {
    columns: [
        {
            name: 'Deadline',
            key: 'deadline',
            // TODO FE sorting relies on this, make it required in table config for
            // types that are not string | number | null
            getCellValue: (date) => date.getTime(),
        },
    ],
    searchable: [],
    sortable: ['deadline'],
};
export const BoardTable = () => {
    const init = useInitData();
    const boardOrders = useOrdersForBoardData();
    const { queryParams, onFilterChange } = useParsedQuery();
    const view = useFrontendTable({
        data: boardOrders.items,
        limit: Infinity,
        tableConfig,
    });
    const filterProps = getFilterProps(init.filter_options, queryParams.filter, onFilterChange);
    const columns = init.filter_options.status
        .filter(({ value }) => {
        const status = queryParams.filter.status;
        return !status || (Array.isArray(status) && status.includes(value));
    })
        .map((status) => ({
        status,
        // TODO remove nonNullable after we fix useFrontendTable types
        items: nonNullable(view).items.filter((item) => item.status === status.value),
    }));
    const updateStatus = (status) => {
        const statusProps = filterProps('status');
        statusProps.onChange(Array.isArray(statusProps.value)
            ? statusProps.value.filter((id) => status.value !== id)
            : init.filter_options.status
                .map((status) => status.value)
                .filter((id) => status.value !== id));
    };
    return (_jsx("div", { className: "flex w-fit space-x-2 h-full", children: columns.map(({ status, items }) => {
            return (_jsxs("div", { className: "flex flex-col w-[320px] bg-greyscale-200 rounded-lg p-[12px] space-y-[12px] overflow-hidden h-fit max-h-full", children: [_jsxs("header", { className: "flex", children: [_jsx("span", { className: "font-brand-t2m text-greyscale-600", children: status.name }), _jsx(Badge, { size: "medium", className: cx('ml-1 bg-greyscale-100 text-secondary-300 mr-auto', isClosedOrCanceled(status.value) && 'invisible'), children: items.length }), _jsx(Menu, { trigger: (props) => (_jsx(Button.Icon, { ...props, icon: faEllipsisVertical, color: "grey" })), children: _jsx(Menu.Item, { onClick: () => updateStatus(status), children: "Hide column" }) })] }), _jsx(Show, { if: !!items.length || isClosedOrCanceled(status.value), children: _jsx("div", { className: "overflow-y-auto max-h-full rounded-[4px] space-y-[12px]", children: isClosedOrCanceled(status.value) ? (_jsxs("div", { className: "px-[12px] py-1 bg-greyscale-0 rounded-[4px] font-brand-b1", children: ["In order to provide good experience, we had to prevent loading closed orders, we have a plan on how to give you this ability in the nearest future, so stay tuned! In a meantime, you can explore those orders by going to a", ' ', _jsx(Link, { to: ORDERS_URL, size: "m", children: "list view" }), "."] })) : (_jsx(ChunkRenderer, { component: BoardItem, propsList: items.map((item) => ({ ...item, key: item.id })) })) }) })] }, status.value));
        }) }));
};
