import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Errors } from '~/common/components';
import { cx } from '~/common/utils';
// TODO dedupe with src/common/components/SelectableTag.tsx
export const Badge = ({ badge: { sign, label }, selected, ...props }) => (_jsxs("button", { ...props, tabIndex: !props.onClick ? -1 : undefined, className: cx('flex items-center h-3 px-1 gap-2 border border-solid rounded-[32px] focus-visible:ring', !props.onClick && 'cursor-default', selected
        ? 'bg-success-50 text-success-400 border-transparent'
        : 'bg-greyscale-50 text-greyscale-400 border-greyscale-200'), children: [_jsx("span", { className: cx('font-brand-t4b', { 'text-greyscale-500': !selected }), children: sign }), _jsx("span", { className: "font-brand-c1r", children: label })] }));
// TODO extract this to some MultiAnySelect component that is just logic w/o presentation
export const BadgeSelect = ({ title, badges, value, onChange, errors }) => {
    const makeBadge = (badge) => {
        const selected = value.includes(badge.id);
        const handleClick = () => {
            onChange(selected ? value.filter((vId) => vId !== badge.id) : [...value, badge.id]);
        };
        return _jsx(Badge, { badge: badge, selected: selected, onClick: handleClick }, badge.id);
    };
    return (_jsxs("div", { children: [title && _jsx("span", { className: "block font-brand-b1 mb-1", children: title }), _jsx("div", { className: "flex flex-wrap gap-2", children: badges.map(makeBadge) }), errors && _jsx(Errors, { errors: errors, color: true })] }));
};
