import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { faCalendarDay, faGears, faShapes, faTrashAlt, } from '@fortawesome/pro-regular-svg-icons';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { useMemo, useState } from 'react';
import { Badge, Button, DeleteShell, IconBox, Link, ModalCore, Tabs, useModalContext, } from '~/common/components';
import { useTickState } from '~/common/hooks';
import { cx, formatDayMonthYear, getDateWithoutTime, joinTruthy, nonNullable, pluralize, } from '~/common/utils';
import { ORDERS_URL } from '~/orders';
import { useCurrentUserData } from '~/root';
import { useRmsContext } from '../context';
import { useDeleteCapacityChange } from '../hooks';
import { CapacityChanging } from './CapacityChanging';
import { OrderOverview } from './OrderOverview';
const Capacity = ({ text, capacity, over }) => (_jsxs("div", { className: "flex flex-col items-center gap-[2px] p-1 rounded-lg font-brand-c1r text-greyscale-400 bg-greyscale-100", children: [_jsx("span", { children: text }), _jsx("span", { className: cx('font-brand-t1m', over && capacity > 0 ? 'text-danger-400' : 'text-greyscale-600'), children: capacity })] }));
const Order = ({ id, used, total, status, contributors, onClick, subscription = false, }) => {
    return (_jsxs("li", { className: cx('relative overflow-hidden list-none pt-[4px] pb-1 pl-[20px] pr-[12px]', 'border border-solid border-greyscale-100 rounded-lg hover:bg-greyscale-50 cursor-pointer'), onClick: onClick, children: [_jsx("div", { className: cx('absolute left-0 top-0 w-1 h-full', subscription ? 'bg-secondary-300' : 'bg-primary-300') }), _jsxs("div", { className: "flex items-baseline justify-between font-brand-t5", children: [_jsxs(Link, { size: "m-bold", color: "grey", to: `${ORDERS_URL}/${id}`, children: ["#", id] }), _jsx("span", { className: "ml-auto leading-6", children: used }), _jsxs("span", { className: "font-brand-c1r text-greyscale-500", children: [' ', "/ ", total, " point", pluralize(used)] })] }), _jsx("div", { className: "font-brand-b2r text-greyscale-500 mb-[4px]", children: status }), _jsx("div", { className: "flex justify-between", children: _jsx("div", { className: "flex flex-wrap gap-x-1 gap-y-[4px]", children: contributors.map(({ id, name, is_tagged }) => (_jsx(Badge, { className: "font-brand-c2m px-[6px] py-[1px]", color: is_tagged ? 'grey' : subscription ? 'secondary' : 'primary', children: name }, id))) }) })] }));
};
export const DayOverview = ({ capacityTotals }) => {
    const { date } = useRmsContext();
    const tabs = [
        { key: 'orders', label: `Orders (${capacityTotals.orders.length})` },
        { key: 'changes', label: `Capacity changes (${capacityTotals.capacity_changes.length})` },
    ];
    const [activeTab, setActiveTab] = useState(tabs[0].key);
    const [formattedTodayDate] = useTickState(() => formatDayMonthYear(new Date()), 3600);
    const formattedSelectedDate = formatDayMonthYear(date);
    const isToday = formattedTodayDate === formattedSelectedDate;
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: "flex flex-none items-center mb-2 font-brand-b1 text-greyscale-500", children: [_jsx("div", { className: "font-medium text-[19px] leading-6 text-greyscale-600 mr-auto", children: "Day overview" }), isToday && _jsx(Badge, { color: "primary", children: "Today" }), _jsx(IconBox, { size: "m", className: "ml-1 mr-[4px] text-greyscale-500", icon: faCalendarDay }), _jsx("span", { children: formattedSelectedDate })] }), _jsxs("div", { className: "grid grid-cols-3 gap-2", children: [_jsx(Capacity, { text: "Day capacity", capacity: capacityTotals.capacity_totals.total }), _jsx(Capacity, { text: "Capacity left", capacity: capacityTotals.capacity_totals.left }), _jsx(Capacity, { text: "Over capacity", capacity: capacityTotals.capacity_totals.over, over: true })] }), _jsx(Tabs, { active: activeTab, className: "font-brand-t2m text-greyscale-500 mt-2 flex items-center gap-5 mb-2 border-b-2 border-b-solid border-b-greyscale-200", onChange: setActiveTab, tabs: tabs }), activeTab === 'orders' && _jsx(Orders, { orders: capacityTotals.orders }), activeTab === 'changes' && (_jsx(CapacityChanges, { capacityChanges: capacityTotals.capacity_changes }))] }));
};
const Orders = ({ orders }) => {
    const { modalOpener } = useModalContext();
    if (!orders.length) {
        return _jsx(Empty, { title: "No orders for this day", icon: faShapes });
    }
    return (_jsx("ul", { className: "flex-auto space-y-1 overflow-y-auto overscroll-contain", children: orders.map((order) => (_jsx(Order, { onClick: modalOpener(OrderOverview, { id: order.id }), id: order.id, status: order.status, used: order.capacity_used, total: order.capacity_total, contributors: order.contributors, subscription: order.is_subscription }, order.id))) }));
};
const CapacityChanges = ({ capacityChanges, }) => {
    const { permissions } = useCurrentUserData();
    const [capacityChangeToRemove, setCapacityChangeToRemove] = useState();
    const { mutateAsync: removeCapacityChange, isLoading } = useDeleteCapacityChange();
    const cancelRemoving = () => {
        setCapacityChangeToRemove(null);
    };
    const handleRemove = () => {
        removeCapacityChange(nonNullable(capacityChangeToRemove)).then(cancelRemoving);
    };
    return (_jsxs(_Fragment, { children: [capacityChanges.length ? (_jsxs(_Fragment, { children: [_jsx("ul", { "aria-label": "Capacity changes overview", className: "flex-auto space-y-1 overflow-y-auto overscroll-contain", children: capacityChanges.map(({ id, date, designer, points_from, points_to, reason, actor_name, comment }) => (_jsx(CapacityChange, { id: id, date: date, designer: designer.name, from: points_from, to: points_to, reason: reason, actor: actor_name, comment: comment, onRemove: () => {
                                setCapacityChangeToRemove({ capacityChangeId: id, designerId: designer.id });
                            } }, id))) }), permissions.rmsUpdate && (_jsx("div", { className: "mt-[3px]", children: _jsx(AddCapacityChange, {}) }))] })) : (_jsx(Empty, { title: "No changes for this day", icon: faGears, children: permissions.rmsUpdate && _jsx(AddCapacityChange, {}) })), _jsx(DeleteCapacityChangePrompt, { show: !!capacityChangeToRemove, close: cancelRemoving, isLoading: isLoading, onRemove: handleRemove, onCancel: cancelRemoving })] }));
};
const AddCapacityChange = () => {
    const { teamId } = useRmsContext();
    const { modalOpener } = useModalContext();
    return (_jsx(Button, { onClick: modalOpener(CapacityChanging, { teamId }), "aria-label": "Add capacity change", color: "text-secondary", size: "xs", icon: faPlus, children: "Add new change" }));
};
const CapacityChange = ({ date, actor, designer, from, to, reason, comment, onRemove, }) => {
    const { permissions } = useCurrentUserData();
    const [showRemoveButton, setShowRemoveButton] = useState(false);
    const today = useMemo(() => getDateWithoutTime(), []);
    const canDelete = permissions.rmsUpdate && date.getTime() >= today.getTime();
    const isRemoveButtonVisible = showRemoveButton && canDelete;
    const changeReason = joinTruthy([reason, comment], ': ');
    return (_jsxs("li", { className: "list-none relative px-2 py-1 font-brand-b2r bg-greyscale-100 rounded-[12px]", onMouseEnter: () => setShowRemoveButton(true), onMouseLeave: () => setShowRemoveButton(false), children: [_jsxs("div", { className: "flex justify-between mb-[4px] font-brand-c1r text-greyscale-500", children: [_jsx("span", { children: formatDayMonthYear(date) }), _jsx("span", { className: cx(isRemoveButtonVisible && 'invisible'), children: actor !== null && actor !== void 0 ? actor : '—' })] }), _jsxs("p", { className: "font-brand-b2r mb-[2px]", children: ["Capacity change for ", _jsx("span", { className: "font-brand-b2m", children: designer }), " from", ' ', _jsx("span", { className: "text-primary-300 font-brand-b2m", children: from }), " to", ' ', _jsx("span", { className: "text-primary-300 font-brand-b2m", children: to })] }), _jsx("p", { className: "mb-[4px] font-brand-b2r text-greyscale-500", children: changeReason }), isRemoveButtonVisible && (_jsx(Button.Icon, { "data-stop-propagation": true, size: "m", className: "absolute top-[1px] right-1", icon: faTrashAlt, onClick: onRemove }))] }));
};
const DeleteCapacityChangePrompt = ({ isLoading, onCancel, onRemove, ...modalProps }) => {
    return (_jsx(ModalCore, { className: "w-[494px]", ...modalProps, children: _jsx(DeleteShell, { loading: isLoading, heading: "Delete the change?", description: "Do you want to delete the last capacity change?", submitText: "Yes, delete it", cancelText: "No, keep it", onSubmit: onRemove, onClose: onCancel }) }));
};
function Empty({ icon, title, children, }) {
    return (_jsxs("div", { className: "flex flex-col items-center justify-center gap-[10px] flex-auto font-brand-b1", children: [_jsx(IconBox, { size: "m", icon: icon }), _jsx("span", { className: "text-greyscale-500", children: title }), children] }));
}
