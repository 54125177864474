import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useForm, validator } from 'formoid';
import { useEffect } from 'react';
import { ModalHeader, ModalStepShell, Show } from '~/common/components';
import { useStep } from '~/common/hooks';
import { customValidator, isNonNullable, notifyBackendError, propagateBackendValidationErrors, record, requiredMessage, } from '~/common/utils';
import { EditOrganizationInfoView, EditPortalAndDomainsInfoView, FormSectionHeader, } from '~/organizations/common';
import { useCreateOrganization } from '../hooks';
import { formValidateEmailChips } from '../utils';
const MAX_STEP = 1;
const initialValues = {
    name: '',
    url: '',
    owner_email: '',
    company_logo_id: null,
    email_domains: [],
    autoassign_on_signup: false,
    sync_existing_customers: false,
    custom_portal_enabled: false,
    autoassign_on_new_order: false,
};
export const AddOrganizationPopup = ({ onClose }) => {
    const { step, toPrevStep, toNextStep, toStep } = useStep(MAX_STEP);
    const validateIfStepIs = (requiredStep, requiredValidator) => step === requiredStep ? requiredValidator : null;
    const { fieldProps, setErrors, handleSubmit, values, errors, setValues } = useForm({
        initialValues,
        validationStrategy: 'onChange',
        validators: () => {
            return {
                name: validateIfStepIs(0, validator.minLength(1, requiredMessage('Name'))),
                url: validateIfStepIs(0, customValidator.link()),
                owner_email: validateIfStepIs(0, customValidator.email()),
                company_logo_id: null,
                email_domains: validateIfStepIs(1, formValidateEmailChips),
                autoassign_on_signup: null,
                sync_existing_customers: null,
                custom_portal_enabled: null,
                autoassign_on_new_order: null,
            };
        },
    });
    const { mutateAsync, isLoading } = useCreateOrganization();
    const submit = () => handleSubmit((values) => {
        if (step < MAX_STEP) {
            toNextStep();
            setValues((values) => {
                //This logic migth be a bit distracting when you are constantly going back and forth between steps
                const domains = values.email_domains.map((domain) => domain.value);
                const ownerEmail = values.owner_email.trim().split('@')[1];
                if (domains.includes(ownerEmail))
                    return values;
                return {
                    ...values,
                    email_domains: [{ value: ownerEmail, isValid: true }, ...values.email_domains],
                };
            });
            return Promise.resolve();
        }
        const payload = {
            ...values,
            email_domains: values.email_domains.map((domain) => domain.value),
        };
        return mutateAsync(payload)
            .then(onClose)
            .catch((error) => propagateBackendValidationErrors(error, setErrors))
            .catch(notifyBackendError);
    });
    useEffect(() => {
        if (record.values(errors).find(isNonNullable)) {
            toStep(0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errors]);
    return (_jsxs(_Fragment, { children: [_jsx(ModalHeader, { onClose: onClose, title: "Create new organization" }), _jsxs(ModalStepShell, { className: "min-h-[470px] w-[608px] px-3 pt-3", submitText: "Create organization", step: step, toPrevStep: toPrevStep, maxStep: MAX_STEP, onClose: onClose, onSubmit: submit, loading: isLoading, children: [_jsxs(Show, { if: step === 0, children: [_jsx(FormSectionHeader, { title: "General information" }), _jsx("div", { className: "px-1 space-y-3", children: _jsx(EditOrganizationInfoView, { fieldProps: fieldProps, setErrors: setErrors }) })] }), _jsx(Show, { if: step === 1, children: _jsx(EditPortalAndDomainsInfoView, { fieldProps: fieldProps, values: values, isStandalone: true }) })] })] }));
};
