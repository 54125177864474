import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { faSpinnerThird, faUsers } from '@fortawesome/pro-regular-svg-icons';
import { IconBox } from '~/common/components';
import { Nothing, Table, useFrontendTable } from '~/common/kits/table';
import { useTeamMembersList } from '~/customers/hooks';
import { Header } from './Header';
import { tableConfig } from './table-config';
export const TeamMembers = () => {
    const teamMembers = useTeamMembersList();
    const view = useFrontendTable({ data: teamMembers.data, tableConfig });
    if (!teamMembers.data || !view) {
        return (_jsx("div", { className: "flex items-center justify-center h-40", children: _jsx(IconBox, { className: "h-9 w-9 text-6xl text-secondary-300 animate-spin", icon: faSpinnerThird }) }));
    }
    return (_jsxs(_Fragment, { children: [_jsx(Header, {}), _jsx(Table, { ...view, noDataPlaceholder: _jsx(Nothing, { icon: faUsers, className: "h-[calc(100vh-268px)]" }) })] }));
};
