import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useForm } from 'formoid';
import { ModalHeader, ModalShell } from '~/common/components';
import { isNonNullable, notifyBackendError, propagateBackendValidationErrors, record, } from '~/common/utils';
import { EditPortalAndDomainsInfoView } from '~/organizations/common';
import { useEditCustomPortalAndDomainsInfo, useOrganizationData } from '~/organizations/hooks';
import { formValidateEmailChips } from '~/organizations/utils';
export const EditPortalAndDomainsInfoPopup = ({ onClose }) => {
    const { mutateAsync, isLoading } = useEditCustomPortalAndDomainsInfo();
    const organization = useOrganizationData();
    const initialValues = {
        sync_existing_customers: false,
        autoassign_on_signup: organization.autoassign_on_signup,
        custom_portal_enabled: organization.custom_portal_enabled,
        email_domains: organization.allowed_domains || [],
        autoassign_on_new_order: organization.autoassign_on_new_order,
    };
    const { fieldProps, setErrors, handleSubmit, values, errors } = useForm({
        initialValues,
        validationStrategy: 'onChange',
        validators: () => ({
            sync_existing_customers: null,
            autoassign_on_signup: null,
            custom_portal_enabled: null,
            email_domains: formValidateEmailChips,
            autoassign_on_new_order: null,
        }),
    });
    const submit = () => handleSubmit((values) => {
        var _a;
        return mutateAsync({
            ...values,
            sync_existing_customers: undefined,
            email_domains: (_a = values.email_domains) === null || _a === void 0 ? void 0 : _a.map((domain) => domain.value),
        })
            .then(onClose)
            .catch((error) => propagateBackendValidationErrors(error, setErrors))
            .catch(notifyBackendError);
    });
    const formHasErrors = !!record.values(errors).find(isNonNullable);
    return (_jsxs(_Fragment, { children: [_jsx(ModalHeader, { title: "Edit allowed domains and custom portal", onClose: onClose }), _jsx(ModalShell, { loading: isLoading, disabled: formHasErrors, onClose: onClose, onSubmit: submit, className: "w-[608px] p-3", children: _jsx(EditPortalAndDomainsInfoView, { values: values, fieldProps: fieldProps }) })] }));
};
