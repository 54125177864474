import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { faTimesCircle } from '@fortawesome/pro-solid-svg-icons';
import { useMemo } from 'react';
import { Button, IconBox, SelectableTag } from '~/common/components';
import { getFilterProps, useParsedQuery } from '~/common/kits/table';
import { useInitData } from '../hooks';
import { useOmittedFilters } from './hooks';
const getUpdatedFilterValue = (value, newValue) => {
    if (Array.isArray(value)) {
        return value.filter((v) => v !== newValue);
    }
    return undefined;
};
const resetFilters = () => {
    const newFilterMap = {
        status: undefined,
        manager: undefined,
        team: undefined,
        payment_status: undefined,
        customer_type: undefined,
        custom_portal: undefined,
        organisation: undefined,
        completed: undefined,
    };
    return newFilterMap;
};
const Row = ({ title, value, options, onChange, mountResetButton = false }) => {
    const { updateFilters } = useParsedQuery();
    const values = useMemo(() => {
        if (value) {
            return options.filter((option) => Array.isArray(value) ? value.includes(option.value) : value === option.value);
        }
    }, [value, options]);
    return (_jsxs("div", { className: "flex items-center gap-1", children: [_jsxs("span", { className: "text-greyscale-400 font-brand-b2r", children: [title, ":"] }), _jsxs("div", { className: "flex gap-1 flex-wrap", children: [values === null || values === void 0 ? void 0 : values.map((val) => (_jsx(SelectableTag, { label: _jsxs("span", { className: "flex gap-[10px] items-center", children: [val.name, _jsx(IconBox, { size: "s", icon: faTimesCircle })] }), onClick: () => onChange(getUpdatedFilterValue(value, val.value)), className: "cursor-pointer", children: val.name }, val.value))), mountResetButton && (_jsx(Button, { size: "xs", color: "text-secondary", className: "ml-2", onClick: () => updateFilters(resetFilters), children: "Clear All" }))] })] }));
};
export const FilterBar = () => {
    const omittedFilters = useOmittedFilters();
    const { queryParams, onFilterChange } = useParsedQuery();
    const { filter_options } = useInitData();
    const filterProps = getFilterProps(filter_options, queryParams.filter, onFilterChange);
    const filters = useMemo(() => {
        const filteredFilters = [
            {
                title: 'Status',
                name: 'status',
                ...filterProps('status'),
            },
            {
                title: 'Type',
                name: 'customer_type',
                ...filterProps('customer_type'),
            },
            {
                title: 'PM',
                name: 'manager',
                ...filterProps('manager'),
            },
            {
                title: 'Design team',
                name: 'team',
                ...filterProps('team'),
            },
            {
                title: 'Payment status',
                name: 'paymentStatus',
                ...filterProps('payment_status'),
            },
            {
                title: 'Portal',
                name: 'custom_portal',
                ...filterProps('custom_portal'),
            },
            {
                title: 'Portal',
                name: 'custom_portal',
                ...filterProps('custom_portal'),
            },
            {
                title: 'Organization',
                name: 'organisation',
                ...filterProps('organisation'),
            },
            {
                title: 'Completed',
                name: 'completed',
                ...filterProps('completed'),
            },
        ].filter((item) => !omittedFilters.includes(item.name));
        return filteredFilters;
    }, [filterProps, omittedFilters]);
    const appliedFilters = Object.entries(queryParams.filter)
        .map(([name]) => filters.find((filter) => name === filter.name))
        .filter(Boolean);
    return (_jsx(_Fragment, { children: !!appliedFilters.length && (_jsx("div", { className: "flex flex-col gap-1", children: appliedFilters.map((props, index) => {
                var _a;
                return (_jsx(Row, { mountResetButton: index === appliedFilters.length - 1, ...props }, (_a = props.value) === null || _a === void 0 ? void 0 : _a.toString()));
            }) })) }));
};
