import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faCommentAltDots, faSquareDashedCirclePlus } from '@fortawesome/pro-regular-svg-icons';
import { formatDistanceStrict } from 'date-fns';
import { Badge, IconBox, ImageLoader, Link } from '~/common/components';
import { addSlug, cx, isPositiveInteger, lastItem, nonNullable } from '~/common/utils';
import { ORDERS_URL } from '~/orders';
import { useOrderData } from '~/orders/hooks';
import { FileItem, LinkItem } from '~/orders/Order';
import { useCurrentUserData } from '~/root';
const RevisionLink = ({ children }) => {
    const { id, customer, slides } = useOrderData();
    return (_jsx(Link, { className: "block", size: "xl-bold", to: `${ORDERS_URL}/${id}-${slides}${addSlug(customer.name)}/revisions`, children: children }));
};
const Preview = ({ url }) => {
    if (url) {
        return (_jsx(ImageLoader, { className: "h-16 w-24 rounded object-cover", src: url, alt: "Revision preview" }));
    }
    return (_jsx("figure", { className: "bg-greyscale-200 flex items-center justify-center h-18 w-24", children: _jsx("span", { className: "font-brand-t2m text-greyscale-400", children: "No preview" }) }));
};
export const RevisionSection = () => {
    const { revisions, status } = useOrderData();
    const { permissions } = useCurrentUserData();
    if (permissions.ordersUpdate && status === 'ReadyToSend' && !revisions.length) {
        return (_jsx("div", { className: "bg-greyscale-0 p-1 rounded", children: _jsx(RevisionLink, { children: _jsxs("div", { className: "flex flex-col justify-center items-center h-16 w-full rounded text-secondary-300 bg-greyscale-50 border border-dashed border-greyscale-300", children: [_jsx(IconBox, { size: "l", className: "text-inherit", icon: faSquareDashedCirclePlus }), _jsx("h1", { className: "mt-1", children: "Add first draft" })] }) }) }));
    }
    if (!revisions.length) {
        return null;
    }
    // TODO implement non-fp-ts version of nonEmptyArray, which is useful in
    // cases like this, because checking for length won't narrow the type
    // and lastItem(revisions) could be undefined according to TypeScript,
    // despite always returning a value after checking length
    //
    // though looking at the amount of code needed to play arround
    // nonEmptyArray in the data could easily offset it's usefulness :\
    const revision = nonNullable(lastItem(revisions));
    return (_jsxs("div", { className: "flex gap-1 bg-greyscale-0 p-1 rounded", children: [_jsx(RevisionLink, { children: _jsx(Preview, { url: revision.preview_url }) }), _jsxs("div", { className: "flex flex-col flex-grow p-1 overflow-hidden", children: [_jsxs("div", { className: "flex items-center justify-between flex-wrap gap-[3px]", children: [_jsx(RevisionLink, { children: revision.name }), isPositiveInteger(revision.total_annotations) && (_jsxs(Badge, { className: cx('flex items-center gap-[4px]', {
                                    'text-greyscale-0 bg-danger-400': revision.unresolved_annotations,
                                }), color: revision.unresolved_annotations ? 'danger' : 'grey', children: [_jsx(IconBox, { size: "s", className: "text-inherit", icon: faCommentAltDots }), _jsxs("p", { children: [revision.unresolved_annotations, _jsxs("span", { className: revision.unresolved_annotations ? 'text-danger-300' : 'text-greyscale-400', children: ["/", revision.total_annotations] }), ' ', "annotations"] })] }))] }), _jsxs("p", { className: "text-greyscale-400 font-brand-b2r mb-[12px] mt-[3px]", children: ["Last update: ", formatDistanceStrict(revision.updated, new Date(), { addSuffix: true })] }), revision.file && (_jsx(FileItem, { name: revision.file.name, date: revision.created, link: revision.file.link })), revision.url && _jsx(LinkItem, { link: revision.url }), _jsxs("span", { className: " mt-[5px] font-brand-b1 text-greyscale-500", children: [revision.total_slides, " slide previews"] })] })] }));
};
