import { customersModule } from '~/customers';
import { officesModule } from '~/offices';
import { ordersModule } from '~/orders';
import { organizationsModule } from '~/organizations';
import { rmsModule } from '~/rms';
import { AppShell, handleWindowErrors } from '~/root';
import { posthogInit } from '~/root/posthog';
import { sentryInit } from '~/root/sentry';
import { shiftsModule } from '~/shifts';
import { subscriptionsModule } from '~/subscriptions';
import { teamsModule } from '~/teams';
import { usersModule } from '~/users';
handleWindowErrors();
sentryInit();
posthogInit();
AppShell(ordersModule, usersModule, teamsModule, officesModule, customersModule, subscriptionsModule, rmsModule, shiftsModule, organizationsModule);
