import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useForm } from 'formoid';
import { FileUploadField, ModalHeader, ModalShell } from '~/common/components';
import { useFileUploadState } from '~/common/hooks';
import { propagateBackendValidationErrors } from '~/common/utils';
import { useGeneralInfoMutation, useSubscriptionData } from '~/subscriptions/hooks';
export const EditSubscriptionInfo = ({ onClose }) => {
    var _a, _b, _c;
    const subscription = useSubscriptionData();
    const { mutateAsync } = useGeneralInfoMutation();
    const initialValues = {
        partnership_logo_id: ((_a = subscription.partnership_logo) === null || _a === void 0 ? void 0 : _a.id) || null,
    };
    const { fieldProps, handleSubmit, setErrors, isSubmitting } = useForm({
        initialValues,
        validationStrategy: 'onBlur',
        validators: () => ({
            partnership_logo_id: null,
        }),
    });
    const uploadFieldProps = useFileUploadState({
        tag: 'customer_partnership_logo',
        props: fieldProps('partnership_logo_id'),
        onError: (errors) => setErrors('partnership_logo_id', errors),
        existingFilename: (_c = (_b = subscription.partnership_logo) === null || _b === void 0 ? void 0 : _b.name) !== null && _c !== void 0 ? _c : null,
    });
    const submit = () => handleSubmit((values) => {
        return mutateAsync({
            partnership_logo_id: values.partnership_logo_id === initialValues.partnership_logo_id
                ? undefined
                : values.partnership_logo_id,
        })
            .then(onClose)
            .catch((error) => {
            propagateBackendValidationErrors(error, setErrors);
        });
    });
    return (_jsxs(_Fragment, { children: [_jsx(ModalHeader, { onClose: onClose, title: "Edit customer information" }), _jsx(ModalShell, { className: "p-3 pb-5 space-y-3 w-[494px]", onClose: onClose, onSubmit: submit, loading: isSubmitting, children: _jsx(FileUploadField, { ...uploadFieldProps, title: "Partnership logo", hintLeft: "Supports: PNG, JPG", hintRight: "Up to 2 MB", accept: ".png, .jpg, .jpeg" }) })] }));
};
